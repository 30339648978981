"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-wrap kiwi-error"
  }, [_c('img', {
    attrs: {
      "src": require("../res/salvadorkatz_kiwi.png"),
      "title": "deviantart.com/salvadorkatz [CC BY-NC-ND 3.0]",
      "alt": ""
    }
  }), _vm._m(0), _c('span', {
    staticClass: "kiwi-error-text"
  }, [_vm._v(_vm._s(_vm.error))])]);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('h2', [_vm._v("There was a problem starting "), _c('span', {
    staticClass: "kiwi-name"
  }, [_vm._v("LewdChat")])]);
}];
exports.staticRenderFns = staticRenderFns;