"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-nicklist",
    class: {
      'kiwi-nicklist--filtering': _vm.userFilterVisible,
      'kiwi-nicklist--avatars': _vm.shouldShowAvatars
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "kiwi-nicklist-filter",
    on: {
      "click": _vm.toggleUserFilter
    }
  }, [_c('div', {
    staticClass: "kiwi-nicklist-usercount"
  }, [_vm._v(" " + _vm._s(_vm.userFilterVisible ? _vm.sortedUsers.length : _vm.$t('person', {
    count: _vm.sortedUsers.length
  })) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userFilter,
      expression: "userFilter"
    }],
    ref: "user_filter",
    attrs: {
      "placeholder": _vm.$t('filter_users')
    },
    domProps: {
      "value": _vm.userFilter
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      },
      "blur": _vm.onFilterBlur,
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.userFilter = $event.target.value;
      }
    }
  }), _c('i', {
    staticClass: "fa fa-search",
    attrs: {
      "aria-hidden": "true"
    }
  }), _c('div', {
    staticClass: "kiwi-nicklist-usercount-width"
  }, [_vm._v(" " + _vm._s(_vm.allUsersCount) + " ")])]), _c('RecycleScroller', {
    staticClass: "kiwi-nicklist-users",
    attrs: {
      "items": _vm.sortedUsers,
      "item-size": _vm.shouldShowAvatars ? 38 : 26,
      "key-field": 'id'
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('nicklist-user', {
          key: item.id,
          attrs: {
            "user": item,
            "nicklist": _vm.self,
            "network": _vm.network
          }
        })];
      }
    }])
  })], 1);
};
exports.render = render;
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "margin": "0",
      "padding": "0",
      "text-align": "center"
    }
  }, [_c('script', {
    attrs: {
      "type": "application/javascript",
      "data-cfasync": "false",
      "async": "",
      "src": "https://poweredby.jads.co/js/jads.js"
    }
  }), _c('ins', {
    attrs: {
      "id": "1052809",
      "data-width": "300",
      "data-height": "112"
    }
  }), _c('script', {
    attrs: {
      "type": "application/javascript",
      "data-cfasync": "false",
      "async": ""
    }
  }, [_vm._v("(adsbyjuicy = window.adsbyjuicy || []).push({'adzone':1052809});")])]);
}];
exports.staticRenderFns = staticRenderFns;