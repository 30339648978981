"use strict";
'kiwi public';

var _typeof = require("@babel/runtime-corejs3/helpers/typeof");
var _WeakMap = require("@babel/runtime-corejs3/core-js-stable/weak-map");
var _Object$getOwnPropertyDescriptor = require("@babel/runtime-corejs3/core-js-stable/object/get-own-property-descriptor");
var _interopRequireDefault = require("@babel/runtime-corejs3/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _trim = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/trim"));
var _concat = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/concat"));
var _sort = _interopRequireDefault(require("@babel/runtime-corejs3/core-js-stable/instance/sort"));
var ipRegex = _interopRequireWildcard(require("ip-regex"));
var TextFormatting = _interopRequireWildcard(require("@/helpers/TextFormatting"));
var IrcdDiffs = _interopRequireWildcard(require("@/helpers/IrcdDiffs"));
var Misc = _interopRequireWildcard(require("@/helpers/Misc"));
var _GlobalApi = _interopRequireDefault(require("@/libs/GlobalApi"));
var _Html = _interopRequireDefault(require("@/libs/renderers/Html"));
var _MessageParser = _interopRequireDefault(require("@/libs/MessageParser"));
var _UserAvatar = _interopRequireDefault(require("./UserAvatar"));
var _AwayStatusIndicator = _interopRequireDefault(require("./AwayStatusIndicator"));
function _getRequireWildcardCache(nodeInterop) { if (typeof _WeakMap !== "function") return null; var cacheBabelInterop = new _WeakMap(); var cacheNodeInterop = new _WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && _Object$getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? _Object$getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var _default = {
  components: {
    UserAvatar: _UserAvatar.default,
    AwayStatusIndicator: _AwayStatusIndicator.default
  },
  props: ['network', 'buffer', 'user', 'sidebarState'],
  data: function data() {
    return {
      self: this,
      whoisRequested: false,
      whoisLoading: false,
      inviteChan: '',
      pluginUiButtonElements: _GlobalApi.default.singleton().userboxButtonPlugins,
      pluginUiInfoElements: _GlobalApi.default.singleton().userboxInfoPlugins,
      pluginUiWhoisElements: _GlobalApi.default.singleton().userboxWhoisPlugins
    };
  },
  computed: {
    // Channel modes differ on some IRCds so get them from the network options
    availableChannelModes: function availableChannelModes() {
      var availableModes = [];
      var prefixes = this.network.ircClient.network.options.PREFIX;
      var knownPrefix = {
        q: 'Owner',
        a: 'Admin',
        o: 'Operator',
        h: 'Half-Operator',
        v: 'Voice'
      };
      prefixes.forEach(function (prefix) {
        var mode = prefix.mode;
        if (knownPrefix[mode]) {
          availableModes.push({
            mode: mode,
            description: knownPrefix[mode]
          });
        }
      });
      return availableModes;
    },
    areWeAnOp: function areWeAnOp() {
      if (!this.buffer) {
        return false;
      }
      return this.buffer.isUserAnOp(this.buffer.getNetwork().nick);
    },
    realname: function realname() {
      var _context;
      return (0, _trim.default)(_context = this.user.realname || '').call(_context);
    },
    formattedRealname: function formattedRealname() {
      var blocks = (0, _MessageParser.default)(this.realname, {
        extras: false
      });
      var content = (0, _Html.default)(blocks, false);
      return content;
    },
    isUserOnBuffer: function isUserOnBuffer() {
      if (!this.buffer) {
        return false;
      }
      if (!this.user.buffers[this.buffer.id]) {
        // Probably switched buffer while the userbox was open
        return false;
      }
      return true;
    },
    userMode: {
      get: function get() {
        if (!this.buffer) {
          return '';
        }
        var userBufferInfo = this.user.buffers[this.buffer.id];
        if (!userBufferInfo) {
          // Probably switched buffer while the userbox was open
          return '';
        }
        var modes = userBufferInfo.modes;
        return modes.length > 0 ? modes[0] : '';
      },
      // Switch the current user mode for the new one
      set: function set(newVal) {
        var _context2;
        var client = this.network.ircClient;
        var oldVal = this.userMode;
        var changes = [];
        var targets = [];
        if (oldVal) {
          changes.push('-' + oldVal);
          targets.push(this.user.nick);
        }
        if (newVal) {
          changes.push('+' + newVal);
          targets.push(this.user.nick);
        }
        var params = (0, _concat.default)(_context2 = ['MODE', this.buffer.name, changes.join('')]).call(_context2, targets);
        client.raw(params);
      }
    },
    userChannels: function userChannels() {
      var _context3, _context4;
      var channels = (0, _sort.default)(_context3 = (0, _trim.default)(_context4 = this.user.whois.channels).call(_context4).split(' ')).call(_context3, Misc.strCompare);
      for (var i = 0; i < channels.length; i++) {
        channels[i] = TextFormatting.linkifyChannels(channels[i]);
      }
      return channels.join(' ');
    },
    isSelf: function isSelf() {
      return this.user === this.network.currentUser();
    },
    invitableBuffers: function invitableBuffers() {
      var _this = this;
      var buffers = [];
      this.network.buffers.forEach(function (buffer) {
        if (buffer.isChannel() && buffer.isUserAnOp(_this.network.nick) && !buffer.hasNick(_this.user.nick)) {
          buffers.push(buffer.name);
        }
      });
      return buffers;
    }
  },
  watch: {
    user: function user() {
      // Reset the whois view since the user is now different
      this.whoisRequested = false;
      this.whoisLoading = false;
    }
  },
  methods: {
    openQuery: function openQuery() {
      this.sidebarState.showNicklist();
      var buffer = this.$state.addBuffer(this.network.id, this.user.nick);
      this.$state.setActiveBuffer(this.network.id, buffer.name);
      if (this.$state.ui.is_narrow) {
        this.$state.$emit('userbox.hide');
      }
    },
    onChannelsClick: function onChannelsClick(event) {
      var channelName = event.target.getAttribute('data-channel-name');
      if (channelName) {
        var network = this.buffer.getNetwork();
        this.$state.addBuffer(this.buffer.networkid, channelName);
        network.ircClient.join(channelName);
      }
    },
    updateWhoisData: function updateWhoisData() {
      var _this2 = this;
      this.whoisRequested = true;
      this.whoisLoading = true;
      this.network.ircClient.whois(this.user.nick, function () {
        _this2.whoisLoading = false;
      });
    },
    kickUser: function kickUser() {
      var reason = this.$state.setting('buffers.default_kick_reason');
      this.network.ircClient.raw('KICK', this.buffer.name, this.user.nick, reason);
    },
    createBanMask: function createBanMask() {
      // try to ban via user account first
      if (this.user.account) {
        // if EXTBAN is supported use that
        var extban = IrcdDiffs.extbanAccount(this.network);
        if (extban) {
          return extban + ':' + this.user.account;
        }

        // if the account name is in the host ban the host
        // Eg. user@network/user/accountname
        if (this.user.host.toLowerCase().indexOf(this.user.account.toLowerCase()) > -1) {
          return '*!*@' + this.user.host;
        }
      }

      // if an ip address is in the host and not the whole host ban the ip
      // Eg. user@gateway/1.2.3.4
      var ipTest = new RegExp('(' + ipRegex.v4().source + '|' + ipRegex.v6().source + ')');
      if (ipTest.test(this.user.host)) {
        var match = this.user.host.match(ipTest)[0];
        if (match !== this.user.host) {
          return '*!*@*' + match + '*';
        }
      }

      // if an 8 char hex is the username ban by username. Commonly used in gateways
      // Eg. 59d4c432@a.clients.lewdchat.com
      var hexTest = /^([a-f0-9]{8})$/i;
      if (hexTest.test(this.user.username)) {
        var _match = this.user.username.match(hexTest)[0];
        return '*!' + _match + '@*';
      }

      // fallback to default_ban_mask from config
      var mask = this.$state.setting('buffers.default_ban_mask');
      mask = mask.replace('%n', this.user.nick);
      mask = mask.replace('%i', this.user.username);
      mask = mask.replace('%h', this.user.host);
      return mask;
    },
    banUser: function banUser() {
      if (!this.user.username || !this.user.host) {
        return;
      }
      var banMask = this.createBanMask();
      this.network.ircClient.raw('MODE', this.buffer.name, '+b', banMask);
    },
    kickbanUser: function kickbanUser() {
      if (!this.user.username || !this.user.host) {
        return;
      }
      var banMask = this.createBanMask();
      var reason = this.$state.setting('buffers.default_kick_reason');
      this.network.ircClient.raw('MODE', this.buffer.name, '+b', banMask);
      this.network.ircClient.raw('KICK', this.buffer.name, this.user.nick, reason);
    },
    inviteUser: function inviteUser() {
      if (!this.inviteChan) {
        return;
      }
      this.network.ircClient.invite(this.inviteChan, this.user.nick);
    }
  }
};
exports.default = _default;
window._kiwi_exports = window._kiwi_exports || {};
if(!window._kiwi_exports["components"]) window._kiwi_exports["components"] = {};
window._kiwi_exports["components"]["UserBox"]
window._kiwi_exports.components.UserBox = exports.default ? exports.default : exports;
